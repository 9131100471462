import React, {useEffect, useState} from 'react';
import Title from "../components/Title";
import Separator from "../components/Separator";
import Button from "../components/Button";
import DropFile from "../components/DropFile";
import FileList from "../components/FileList";
import ButtonBrowse from "../components/ButtonBrowse";
import uploadFile from "../utils/UploadFile";
import {useNavigate} from "react-router-dom";
import HiddenInput from "../components/HiddenInput";
import CheckBox from "../components/CheckBox";
import SectionTitle from "../components/SectionTitle";
import fetchRetry from "../utils/fetchRetry";

import {createMessage, encrypt, readKey} from "openpgp";
import {ethers} from "ethers";
import piexif from "piexifjs";
import {useDebounce} from "../hooks/useDebounce";
import IconError from "../assets/icons/IconError";
import IconGear from "../assets/icons/IconGear";
import IconComplete from "../assets/icons/IconComplete";
import HiddenSelect from "../components/HiddenSelect";
import Rotator from 'exif-auto-rotate';
import {Uploader} from "../utils/upload";


const SUPPORTED_EXTENSIONS = [".png", ".jpg", ".jpeg", ".gif", ".avi", ".mp4", ".mkv", ".mov", ".webp", ".heic", ".avif", ".svg"];
const SUPPORTED_EXTENSIONS_AUTHED = [".apk",".odp",".ods",".odt",".svg",".png", ".jpg", ".jpeg", ".gif", ".webp", ".heic", ".avif", ".svg", ".avi", ".mp4", ".mkv", ".mov", ".zip", ".rar", ".pdf", ".doc", ".7z", ".xz", ".gz", ".csv", ".bz2", ".xls", ".txt", ".tar", ".kdbx", ".tar.gz", ".tar.xz", ".tar.bz2"];
const MAX_FILE_SIZE_MB = 50;
const MAX_FILE_SIZE_MB_AUTHED = 10240;
const MAX_FILES_NUMBER = 10;
const API_URL = "https://anonimg.top/APIv2/";
const PUBLIC_KEY = `-----BEGIN PGP PUBLIC KEY BLOCK-----

xjMEY87ATRYJKwYBBAHaRw8BAQdApym6GH4J9BxqLnVcP+N6loXWMB425r+8
HcYVlEvhnubNG0pvbiBTbWl0aCA8am9uQGV4YW1wbGUuY29tPsKMBBAWCgA+
BQJjzsBNBAsJBwgJEP59+VrqT5QiAxUICgQWAAIBAhkBAhsDAh4BFiEEjsfo
SLM94cRVYob+/n35WupPlCIAAAKEAQDZ9Q5JCyiE7oRm7Uue+PzDmL44DpvE
Np3So4yL/tTdiQEAmPYGPSjEszzOXG7T1M9E61jPZQOvdOQKRi73fwJ2nQvO
OARjzsBNEgorBgEEAZdVAQUBAQdAZGx5pQxjTx4OaOXXVRqGLXLVxGSm9QbL
zlQBoYQk0R8DAQgHwngEGBYIACoFAmPOwE0JEP59+VrqT5QiAhsMFiEEjsfo
SLM94cRVYob+/n35WupPlCIAAFPJAQDeIVJWFk/urfAOHEIiZDTv1DkZELjl
q/06hk7pOyj4jgEA33Dp/4ZHV+AD7TBOGSK/QoKAs1X0+xBs6Mk00W6HJQw=
=v3qZ
-----END PGP PUBLIC KEY BLOCK-----`;

const Home = () => {
    const navigate = useNavigate();

    const [filesToUpload, setFilesToUpload] = useState([]);
    const [isUploadingInProcess, setIsUploadingInProcess] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const [authCode, setAuthCode] = useState('');
    const [authStatus, setAuthStatus] = useState({status: "false"});
    const debouncedValue = useDebounce(authCode, 700)

    const [comment, setComment] = useState('');
    const [password, setPassword] = useState('');
    const [deleteAfter, setDeleteAfter] = useState(6);

    const [collage, setCollage] = useState(false)
    const [onePost, setOnePost] = useState(false)

    const [previewLink, setPreviewLink] = useState("")
    const [compress, setCompress] = useState(true);
    const [onionLinks, setOnionLinks] = useState(false)

    const [packName] = useState(generateRandomCode());

    // useEffect(()=>{
    //     console.log(getDeleteDate(deleteAfter))
    // },[deleteAfter])

    useEffect(() => {
        if (debouncedValue.length > 0) {
            setAuthStatus({status: "loading"})
            fetchRetry(API_URL + "checkAuth.php", 850, 3, {
                method: "POST",
                body: JSON.stringify({code: authCode})
            }).then(async r => {
                let result = await r.json();
                if (result.result === false) {
                    setAuthStatus({status: "false"})
                    setDeleteAfter(6)
                } else if (result.result === true) {
                    setAuthStatus({status: "true"})
                    setDeleteAfter(7)
                }
            })
        }
    }, [debouncedValue])

    useEffect(() => {
        if (isUploadingInProcess) {
            let notFinished = filesToUpload.filter(item => item.finished === false);
            if (notFinished.length === 0) {
                setIsUploadingInProcess(false);
                setIsFinished(true);
                genSignature("abc6e563408328980d7c5b2bec26c5411256248514ef03e2649ed0a87e196451", packName).then(signature => {
                    navigate('links', {
                        state: {
                            files: filesToUpload,
                            pack: packName,
                            onePost: onePost,
                            isCollage: collage,
                            onionLinks: onionLinks,
                            previewLink: previewLink,
                            deleteLink: (onePost || collage ? "g:" : "i:") + packName + ":" + signature,
                        }
                    })
                });
            }
        }
    }, [filesToUpload, isUploadingInProcess])


    const addFilesToUploadList = async (files) => {
        let filesArray = [...filesToUpload];

        if (filesArray.length + files.length > MAX_FILES_NUMBER) {
            alert(`Maximum number of files is ${MAX_FILES_NUMBER}!`)
            return;
        }
        // тут валидация
        for (let file of files) {

            let fileExtension =  getExtension(file.name);
            console.log(fileExtension)
            if (authStatus.status !== "true" && !SUPPORTED_EXTENSIONS.includes("."+fileExtension.split('.').pop())) {
                alert(`${fileExtension} is wrong extension!`)
                return;
            }
            if (authStatus.status === "true" && !SUPPORTED_EXTENSIONS_AUTHED.includes("."+fileExtension.split('.').pop())) {
                alert(`${fileExtension} is wrong extension!`)
                return;
            }
            if (authStatus.status !== "true" && file.size / 1048576 > MAX_FILE_SIZE_MB) {
                alert(`${file.name} is to big! Maximum file size is ${MAX_FILE_SIZE_MB}!`);
                return;
            }
            if (authStatus.status === "true" && file.size / 1048576 > MAX_FILE_SIZE_MB_AUTHED) {
                alert(`${file.name} is to big! Maximum file size is ${MAX_FILE_SIZE_MB_AUTHED}!`);
                return;
            }

            let data = {
                name: generateRandomCode(),
                ext: fileExtension,
                progress: 0,
                finished: false,
                error: false,
                file: file
            }
            filesArray.push(data);
        }
        setFilesToUpload(filesArray)
    }

    const removeFileFromUploadList = (index) => {
        let filesArray = [...filesToUpload];

        filesArray = filesArray.filter((f, i) => i !== index);

        setFilesToUpload(filesArray)
    }

    const startUpload = async () => {

        setIsUploadingInProcess(true)
        if(onionLinks){
            fetchRetry(API_URL + "get_domain.php", 850, 3, {
                method: "POST",
                body: JSON.stringify({onion: onionLinks})
            }).then(async r => {
                let link = await r.json();
                setPreviewLink(link.link)
            })
        }else{
            setPreviewLink("https://getpic.top/")
        }


        filesToUpload.map(async (file, index) => {
            let ext = getExtension(file.name);
            // let ext = "." + file.name.split('.').pop().split(' ')[0].toLowerCase();
            if (ext === ".jpg" || ext === ".jpeg") {
                file.file = dataURLtoFile(stripExif(await toBase64(file.file)), file.file.name);
            }

            let filesArray = [...filesToUpload];
            filesArray[index].progress = 18;
            setFilesToUpload(filesArray);

            const uploader = new Uploader(file)
            uploader
                .onProgress(({percentage: newPercentage}) => {
                    if (newPercentage !== filesArray[index].progress && newPercentage <= 80) {
                        let filesArray = [...filesToUpload];
                        filesArray[index].progress = newPercentage;
                        setFilesToUpload(filesArray);
                    }
                })
                .onError((error) => {
                    let filesArray = [...filesToUpload];
                    filesArray[index].error = true;
                })
                .onComplete((result) => {
                    filesArray[index].progress = 80;
                    saveFileToDB(filesArray[index], index)
                    setFilesToUpload(filesArray);
                })

            uploader.start()
        })
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }
    function getExtension(fileName) {
        fileName = fileName.toLowerCase()
        var found = null;
        for (var i = 0; i < SUPPORTED_EXTENSIONS_AUTHED.length; i++) {
            var ext = SUPPORTED_EXTENSIONS_AUTHED[i];
            if (fileName.endsWith(ext)) {
                if (found === null || found.length < ext.length) {
                    found = ext;
                }
            }
        }
        return found;
    }
    const retryUpload = (fileIndex) => {
        let filesArray = [...filesToUpload];
        filesArray[fileIndex].error = false;
        setFilesToUpload(filesArray)

        const uploader = new Uploader(filesArray[fileIndex])
        uploader
            .onProgress(({percentage: newPercentage}) => {
                if (newPercentage !== filesArray[fileIndex].progress && newPercentage <= 80) {
                    let filesArray = [...filesToUpload];
                    filesArray[fileIndex].progress = newPercentage;
                    setFilesToUpload(filesArray);
                }
            })
            .onError((error) => {
                let filesArray = [...filesToUpload];
                filesArray[fileIndex].error = true;
            })
            .onComplete((result) => {
                filesArray[fileIndex].progress = 80;
                saveFileToDB(filesArray[fileIndex], fileIndex)
                setFilesToUpload(filesArray);
            })

        uploader.start()
    }

    const saveFileToDB = async (file, index) => {
        const api_url2 = API_URL + "check_file.php";
        let data2 = {filename: file.name}
        let check_result = await fetch(api_url2, {method: "POST", body: JSON.stringify(data2)})

        check_result = JSON.parse(await check_result.text());
        if (check_result.result === "false") {
            let filesArray = [...filesToUpload];
            filesArray[index].error = true;
            setFilesToUpload(filesArray);
        } else {


            let data = {
                name: file.name,
                ext: file.ext,
                pack: packName,
                delete_link: '',
                delete_id: deleteAfter,
                delete_date: getDeleteDate(deleteAfter),
                comment: comment,
                password: password,
                compress: compress ? 1 : 0,
                onePost: onePost ? 1 : 0,
                sort: index,
                size: file.file.size,
            }

            const encoded_data = await encode(JSON.stringify(data));

            const privateKey = "43f8ec5b341c7224b8ddcd23670ad0ea81eccb383fa04826897c6a870c0be482";
            const ts = Date.now();
            const message = `${ts}.${JSON.stringify({"args": [data['title'], data['pack'], encoded_data]})}`;
            const signature = await genSignature(privateKey, message);

            data['ts'] = ts;
            data['sig'] = signature;
            data['data'] = encoded_data;

            const api_url = API_URL + "save_file.php";

            fetchRetry(api_url, 850, 3, {method: "POST", body: JSON.stringify(data)}, true).then((response) => {
                if (response.status === 200) {
                    let filesArray = [...filesToUpload];
                    filesArray[index].progress = 100;
                    filesArray[index].finished = true;
                    filesArray[index].error = false;
                    setFilesToUpload(filesArray);
                } else {
                    let filesArray = [...filesToUpload];
                    filesArray[index].error = true;
                    setFilesToUpload(filesArray);
                }

            }).catch(() => {
                let filesArray = [...filesToUpload];
                filesArray[index].error = true;
                setFilesToUpload(filesArray);
            })

            const api_url_poly = API_URL + "savetopoly.php";

            fetchRetry(api_url_poly, 850, 3, {method: "POST", body: JSON.stringify(data)}, true).catch(() => {
                let filesArray = [...filesToUpload];
                filesArray[index].error = true;
                setFilesToUpload(filesArray);
            })
        }
    }


    const encode = async (string) => {
        const publicKeys = await readKey({armoredKey: PUBLIC_KEY})

        return await encrypt({
            message: await createMessage({text: `${string}`}), // input as Message object
            encryptionKeys: publicKeys,
        });
    };


    function generateRandomCode() {
        const timestamp = Date.now().toString().slice(2, 5);
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
        const codeLength = 15;
        let randomCode = '';
        for (let i = 0; i < codeLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomCode += characters.charAt(randomIndex);
        }
        return timestamp + randomCode;
    }


    const stripExif = (fileDataUrl) => {
        return piexif.remove(fileDataUrl);
    }
    const genSignature = async (privateKey, message) => {
        const provider = ethers.getDefaultProvider(); // Use the default Ethereum provider
        const wallet = new ethers.Wallet(privateKey, provider);
        return await wallet.signMessage(message);
    }
    const getDeleteDate = (deleteId) => {
        let deleteDate = false;
        switch (deleteId) {
            case '3':
                deleteDate = new Date();
                deleteDate.setHours(deleteDate.getHours() + 1); // Add 1 hour
                break;
            case '1':
                deleteDate = new Date();
                deleteDate.setDate(deleteDate.getDate() + 1); // Add 1 day
                break;
            case '2':
                deleteDate = new Date();
                deleteDate.setDate(deleteDate.getDate() + 7); // Add 1 week (7 days)
                break;
            case '4':
                deleteDate = new Date();
                deleteDate.setMonth(deleteDate.getMonth() + 1); // Add 1 month
                break;
            case '5':
                deleteDate = new Date();
                deleteDate.setMonth(deleteDate.getMonth() + 6); // Add 6 month
                break;
            case '6':
                deleteDate = new Date();
                deleteDate.setFullYear(deleteDate.getFullYear() + 1); // Add 1 year
                break;
            case '7':
                deleteDate = new Date();
                deleteDate.setFullYear(deleteDate.getFullYear() + 5); // Add 5 years
                break;
        }

        if (deleteDate) {
            deleteDate = Math.floor(deleteDate.getTime() / 1000);
        }
        return deleteDate;
    }
    return (
        <div className="wrap">
            <Title
                title={
                    !isUploadingInProcess && !isFinished ?
                        "Anonymous Photohosting"
                        :
                        isUploadingInProcess && !isFinished ?
                            "Uploading..."
                            :
                            "Uploading is complete"
                }
            />

            <Separator/>

            <DropFile handleFiles={addFilesToUploadList}
                      accept={authStatus.status === "true" ? SUPPORTED_EXTENSIONS_AUTHED : SUPPORTED_EXTENSIONS}
                      hide={isUploadingInProcess || isFinished}/>

            <Separator hide={isUploadingInProcess || isFinished} hideOnMob={true}/>

            {filesToUpload.length > 0 &&
                <>
                    <FileList files={filesToUpload} handleRemoveFile={removeFileFromUploadList}
                              cancelable={!isUploadingInProcess && !isFinished} retryUpload={retryUpload}/>
                    <Separator/>
                </>
            }
            <SectionTitle text={"Description"} optional={true} hide={isUploadingInProcess || isFinished}/>

            <textarea
                className={`description_input ${(isUploadingInProcess || isFinished) && "hide"}`}
                placeholder="Write something..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                maxLength={500}
            />

            <Separator hide={isUploadingInProcess || isFinished}/>

            <SectionTitle text={"Authorization code"} optional={true} hide={isUploadingInProcess || isFinished}/>

            <label className={"AuthLabel form " + ((isUploadingInProcess || isFinished) ? "hide" : "")}>
                <input type="password" placeholder={"Enter code"} value={authCode} autoComplete="off"
                       onChange={(e) => setAuthCode(e.target.value)}/>
                <span>{authStatus.status === "false" ? <IconError/> : authStatus.status === "loading" ? <IconGear/> :
                    <IconComplete/>}</span>
            </label>

            <Separator hide={isUploadingInProcess || isFinished}/>

            <HiddenInput
                value={password}
                setValue={setPassword}
                text={"Protect password?"}
                placeholder={"Enter password"}
                hide={isUploadingInProcess || isFinished}
            />
            <HiddenSelect
                value={deleteAfter}
                setValue={setDeleteAfter}
                text={"Delete after?"}
                authed={authStatus.status === "true"}
                hide={isUploadingInProcess || isFinished}
            />

            <CheckBox value={onePost} setValue={setOnePost} text={"Make gallery?"}
                      hide={isUploadingInProcess || isFinished} disabled={collage}/>

            <CheckBox text={"Join? (Make one file)"} value={collage} setValue={setCollage}
                      hide={isUploadingInProcess || isFinished}
                      disabled={
                          onePost ||
                          filesToUpload.filter(item => {
                              if (!([".png", ".jpg", ".jpeg", ".gif"].includes(item.ext))) {
                                  return item;
                              }
                          }).length > 0
                      }
            />

            <CheckBox text={"Create onion links?"} value={onionLinks} setValue={setOnionLinks}
                      hide={isUploadingInProcess || isFinished}/>

            <CheckBox value={compress} setValue={setCompress} text={"Compress images?"}
                      hide={isUploadingInProcess || isFinished}/>

            {filesToUpload.length === 0 ?
                <ButtonBrowse text={"Browse Files"}
                              accept={authStatus.status === "true" ? SUPPORTED_EXTENSIONS_AUTHED : SUPPORTED_EXTENSIONS}
                              handleFiles={addFilesToUploadList}/>
                :
                isFinished ?
                    <Button
                        text={"Get Links"}
                        primary={true}
                        handleClick={() => {
                            navigate('links', {
                                state: {
                                    files: filesToUpload,
                                    pack: packName,
                                    onePost: onePost,
                                    isCollage: collage
                                }
                            })
                        }}
                    />
                    :
                    <Button
                        text={isUploadingInProcess ? "Uploading..." : "Upload"}
                        primary={true}
                        disabled={isUploadingInProcess}
                        handleClick={() => {
                            startUpload()
                        }}
                    />
            }

        </div>
    );
};

export default Home;